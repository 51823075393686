@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


:root {
  --secondary-color : #2563EB;
  --secondary-color-text : #2563EB;
  --third-color : #2563EB;
}

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 0px !important;
}

html, body{
  background-color: #0f172a;
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}


.bg-colorSecondary{
  background: var(--secondary-color);
}

.bg-colorSecondaryhover{
  background: var(--secondary-color);
  opacity: 80%;
}

.color-secondary{
  color: var(--secondary-color-text) !important;
}

.border-colorSecondary{
  border: var(--secondary-color) 2px solid;
}


.color-title{
  color: var(--third-color) !important;
}

.bg-ThirdColor{
  background: var(--third-color);
}

.border-ThirdColor{
  border: solid 2px var(--third-color);
}

.paragraph{
  font-size: 1.2rem;
  line-height: 1.6;
  opacity: 0.9;
  margin-bottom: 7%;
  width: 90%;
  text-align: justify;
}
@media (max-width: 1024px) { 
  .paragraph{
    width: 100%;
  }
 }

  .prestation {
    filter: brightness(0.7);
  }
  
  .prestation.active {
    filter: brightness(1.2);
  }
  

#wave {
  width: 100vw;
}


@media screen and (max-width: 768px) {

  .paragraph{
    line-height: 1.3;
    opacity: 1;
    text-align: start;
  }
}
