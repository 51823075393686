.display_projet {
  position: relative;
  width: 32vw;
  margin: 15px;
  overflow: hidden;
}

.display_projet img {
  height: auto;
  transition: all 0.8s ease-in-out;
}

.display_projet:hover img {
  transform: scale(1.24);
  transition: all 0.8s ease-in-out;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70%;
  opacity: 0;
  transition: all 0.8s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateY(100%);
}

.display_projet:hover .overlay {
  opacity: 1;
  transform: translateY(0);
  transition: all .8s ease-in-out;
}

.overlay h5 {
  color: white;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.overlay p {
  color: white;
  font-size: 1rem;
  margin: 0 1rem;
}

@media screen and (max-width: 1024px) {
  .display_projet {
    width: 45%;
  }
  .overlay{
    opacity: 1;
  }
  .projets {
    margin-top: 30px;
  }
  
  .display_projet {
    width: 100%;
    margin: 15px 0;
  }
}
