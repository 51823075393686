.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .8s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient(var(--secondary-color), var(--secondary-color));
}


.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}

.link-underline-active {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(var(--secondary-color), var(--secondary-color));
  background-size: 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .8s ease-in-out;
}


.logo{
    font-family: 'Dancing Script', cursive;
    text-transform: uppercase;
  }